<aside class="navbar navbar-vertical navbar-expand-lg navbar-dark">
  <div class="container-fluid">
    <div class="fullpad">
      <div class="row">
        <div class="col-3 d-flex align-items-center justify-content-end"> <a> <img
              src="assets/images/usermanagement/logo.svg" width="23" height="23" class=" mx-auto d-block">
          </a> </div>
        <div class="col-3">
          <div class="navbar-nav flex-row ">
            <div class="nav-item dropdown dropend"> <a class="nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown" aria-label="Open user menu">
                <ngx-avatar [src]="userImage" [name]="displayName" size="22" [textSizeRatio]="2"
                  [initialsSize]="2"></ngx-avatar>
              </a>
              <div class="left0 dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <div class="mt-1 small  p-2"><strong>{{displayName}}</strong> <br />
                  <span class="small text-muted ">{{designation}}</span>
                </div>
                <a class="dropdown-item" href="{{ oldSiteURL }}/v1/#/users/profile/{{ currentUser.id}}">Profile</a>
                <a (click)="logout()" class="dropdown-item">Logout</a>
                <!-- <a *ngIf="!isV3User()" (click)="logout()" class="dropdown-item"
                                    href="{{ oldSiteURL }}/v1/#/login">Logout</a> -->

              </div>
            </div>
          </div>
        </div>
        <div class="col-3"> <a><i class="ti ti-bell mt-2 white_color mx-auto d-block icon-font"></i></a></div>
        <div class="col-3"> <a (click)="hideNav()"><i class="ti ti-menu-2 mt-2 white_color no-small icon-font"></i></a>
          <button class="navbar-toggler" data-bs-toggle="collapse" (click)="navSelected=!navSelected"
            data-bs-target="#navbar-menu" type="button" [attr.aria-expanded]="navSelected"> <span
              class="navbar-toggler-icon"></span> </button>
        </div>
      </div>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="{show:navSelected}" id="navbar-menu">
      <div class="ps-3 pe-1 small" *ngIf="canSwitchProfile()">
        <div class="nav-item dropdown">
          <a class="nav-link dropdown-toggle align-items-center" data-bs-toggle="dropdown" data-bs-auto-close="outside"
            role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block pt-1">
              <ngx-avatar [src]="userImage" [name]="getActiveSite()?.entityName" size="14" [textSizeRatio]="2"
                [initialsSize]="1"></ngx-avatar>
            </span>
            <span class="nav-link-title wrap-word">
              {{getActiveSite()?.entityName}}
            </span>
          </a>
          <div class="dropdown-menu " style="max-height: 100px;overflow: auto;">
            <a class="dropdown-item small p-2 cursor-pointer" style="background: #2d384f;"
              *ngFor="let site of getProfileListForUser()" (click)="switchProfile(site.id)">
              <span class="nav-link-icon d-md-none d-lg-inline-block pt-1">
                <ngx-avatar [src]="userImage" [name]="site?.entityName" size="14" [textSizeRatio]="2"
                  [initialsSize]="1"></ngx-avatar>
              </span> <span class="wrap-word">{{site?.entityName}}</span>
            </a>

          </div>
        </div>
      </div>
      <ul class="navbar-nav pt-lg-3">


        <li class="nav-item">
          <a [ngClass]="{'active ': (activeRoute('/home'))}" (click)="closeNav()" class="nav-link"
            routerLink="/home"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-home icon-font"></i>
            </span> <span class="nav-link-title"> Home </span> </a>
        </li>

        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveManageDutyLogPermission()">
          <a href="#" (click)="openExternalLink()" class="nav-link">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-books icon-font"></i>
            </span>
            <span class="nav-link-title">Logbook</span>
          </a>
        </li>

        <!--  <li class="nav-item">
                    <a href="#" class="nav-link" routerLink="/ird-register" [ngClass]="{'active': activeRoute('ird-register')}">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-bell-minus icon-font"></i>
                        </span>
                        <span class="nav-link-title">IRD Register</span>
                    </a>
                </li> -->

        <li *ngIf="permissionEvaluator?.isUserHaveDocumentPermission()" class="nav-item">
          <a (click)="closeNav()" [ngClass]="{'active': activeRoute('documents')}" class="nav-link"
            routerLink="/documents"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-file-description icon-font"></i>
            </span> <span class="nav-link-title"> Documents </span> </a>
        </li>

        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveCollaborationPermission()">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active': isCollaborationActive()}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-report-money fs-2"></i>
            </span>
            <span class="nav-link-title"> Collaboration </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{'show': isCollaborationActive()}">
            <div class="dropdown-menu-column">

              <a class="dropdown-item" [ngClass]="{'active': activeRoute('/announcements/list')}"
                routerLink="/announcements/list">
                Announcements
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveSchedulerPermission()" (click)="closeNav()"
                [ngClass]="{'active': activeRoute('/scheduler/my-calendar') }"
                routerLink="/scheduler/my-calendar">Calendar
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveTaskPermission()" (click)="closeNav()"
                [ngClass]="{'active': activeRoute('/task') }" routerLink="/task">Tasks
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveMomPermission()" (click)="closeNav()"
                [ngClass]="{'active': activeRoute('/meetings') }" routerLink="/meetings">MOM
              </a>

              <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('/dashboard/guest') }"
                routerLink="/dashboard/guest" *ngIf="permissionEvaluator.isUserHaveCommunicationsPermission()">Chat
              </a>

            </div>
          </div>
        </li>


        <li class="nav-item" *ngIf="permissionEvaluator?.isUserHaveGuestManagementPermission()">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active': isGuestManagementActive()}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-users fs-2"></i>
            </span>
            <span class="nav-link-title"> Guest Management </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{'show': isGuestManagementActive()}">
            <div class="dropdown-menu-column">

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveGuestEntryPermission()"
                [ngClass]="{'active': activeRoute('room-calender')}" routerLink="/gms/room-calender">
                Rooms Calender
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveCrmPermission()" (click)="closeNav()"
                [ngClass]="{'active': activeRoute('crm/guest') }" routerLink="/crm/guest">Guest Profiles
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveLoyaltyPermission()" (click)="closeNav()"
                [ngClass]="{'active': activeRoute('loyalty') && !activeRoute('loyalty/report') }"
                routerLink="/loyalty">Guest Loyalty
              </a>

              <a *ngIf="permissionEvaluator.isUserHaveLoyaltyReportPermission()"
                [ngClass]="{'active': activeRoute('/loyalty/report')}" (click)="closeNav()" class="dropdown-item"
                routerLink="/loyalty/report">Loyalty Transactions
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveSegmentsPermission()" (click)="closeNav()"
                [ngClass]="{'active': (activeRoute('segments'))}" routerLink="/segments">Segments
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveMarketingPermission()" (click)="closeNav()"
                [ngClass]="{'active': activeRoute('campaign')}" routerLink="/campaign">Campaign
              </a>
            </div>
          </div>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveFeedbackPermission()" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#navbar-base"
            [ngClass]="{'active show': (activeRoute('/feedback')||activeRoute('/reviews'))}" data-bs-toggle="dropdown"
            data-bs-auto-close="false" role="button"
            [attr.aria-expanded]="(activeRoute('/feedback')||activeRoute('/reviews'))">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-messages icon-font"></i>
            </span> <span class="nav-link-title"> Guest Experience </span> </a>
          <div class="dropdown-menu"
            [ngClass]="{'show': (activeRoute('/feedback') || activeRoute('/reviews')||activeRoute('/guest-experience') || activeRoute('/issues') ||activeRoute('/reports'))}">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" (click)="closeNav()" routerLink="/dashboard/guest-experience"
                  [ngClass]="{'active': activeRoute('dashboard/guest-experience')}">
                  Dashboard</a>
                <a class="dropdown-item" (click)="closeNav()" routerLink="/feedback"
                  [ngClass]="{'active': (activeRoute('feedback')&& !activeRoute('feedback/issues'))}">
                  Guest Survey</a>
                <a class="dropdown-item" (click)="closeNav()" routerLink="/feedback/issues"
                  [ngClass]="{'active': activeRoute('feedback/issues')}">
                  Issues</a>

                <a *ngIf="permissionEvaluator.isEntityHaveReviewPermission()" class="dropdown-item dropdown-toggle"
                  href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                  aria-expanded="false"> Reviews </a>


                <div class="dropdown-menu"
                  [ngClass]="{'show': activeRoute('all-reviews')||activeRoute('static-reviews')}">
                  <div class="dropdown-menu-columns">
                    <div class="dropdown-menu-column">
                      <a class="dropdown-item" (click)="closeNav()" routerLink="/reviews/all-reviews"
                        [ngClass]="{'active': activeRoute('all-reviews')}"> All Reviews</a>
                      <a class="dropdown-item" (click)="closeNav()" routerLink="/reviews/static-reviews"
                        [ngClass]="{'active': activeRoute('static-reviews')}"> ORM Score</a>
                    </div>
                  </div>
                </div>

                <a *ngIf="permissionEvaluator.isUserHaveFeedbackReportPermission()" class="dropdown-item "
                  (click)="closeNav()" [ngClass]="{'active': activeRoute('/reports')}" routerLink="/reports">
                  Reports </a>

              </div>
            </div>
          </div>
        </li>

        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveOperationsPermission()">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active show': isOperationsActive()}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-automation">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M13 20.693c-.905 .628 -2.36 .292 -2.675 -1.01a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.492 .362 1.716 2.219 .674 3.03" />
                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                <path d="M17 22l5 -3l-5 -3z" />
              </svg>
              <!-- <i class="ti ti-automation icon-font fs-2"></i> -->
            </span>
            <span class="nav-link-title"> Operations </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{'show': isOperationsActive()}">
            <div class="dropdown-menu-column">

              <a class="dropdown-item" [ngClass]="{'active': activeRoute('/gate-pass')}"
                *ngIf="permissionEvaluator.isUsetHaveGatePassPermission()" routerLink="/gate-pass">
                Gate Pass
              </a>

              <a class="dropdown-item" [ngClass]="{'active': activeRoute('/asset-management')}"
                routerLink="/asset-management">
                Asset management
              </a>

              <a *ngIf="permissionEvaluator.isUserHavePPMPermission()" class="dropdown-item dropdown-toggle"
                href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                aria-expanded="false">PPM
              </a>
              <div class="dropdown-menu"
                [ngClass]="{'show active': activeRoute('ppm-dashboard') || activeRoute('ppm')}">
                <div class="dropdown-menu-columns">
                  <div class="dropdown-menu-column">
                    <a class="dropdown-item" [ngClass]="{ active: activeRoute('ppm-dashboard') }"
                      routerLink="/ppm-cycle/ppm-dashboard">
                      PPM Dashboard </a>
                    <a class="dropdown-item"
                      [ngClass]="{ active: (activeRoute('ppm') && !activeRoute('ppm-dashboard')) }"
                      routerLink="/ppm-cycle">
                      PPM For Rooms </a>
                  </div>
                </div>
              </div>
              <!-- <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHavePPMPermission()" 
              routerLink="/ppm-cycle/ppm-dashboard"
              [ngClass]="{ active: activeRoute('ppm-dashboard') }">
               PPM Dashboard
              </a>

              <a *ngIf="permissionEvaluator.isUserHavePPMPermission()" class="dropdown-item"
              routerLink="/ppm-cycle"
              [ngClass]="{ active: (activeRoute('ppm') && !activeRoute('ppm-dashboard')) }">
               PPM For Rooms
              </a> -->

              <!-- <a class="dropdown-item" (click)="closeNav()" [ngClass]="{'active': activeRoute('/scheduler/my-calendar') }"
      routerLink="/scheduler/my-calendar">Calendar
    </a> -->

              <a class="dropdown-item"
                *ngIf="permissionEvaluator.isUserServiceHKPermission()  && !permissionEvaluator.isRequestManager()"
                [ngClass]="{'active': activeRoute('work-order') }" routerLink="/work-order">Work order
              </a>

              <a *ngIf="permissionEvaluator.isUserServiceHKPermission() && permissionEvaluator.isRequestManager()"
                class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                data-bs-auto-close="false" role="button" aria-expanded="false"> Work order
              </a>

              <div class="dropdown-menu" *ngIf="permissionEvaluator.isRequestManager()"
                [ngClass]="{'show active': activeRoute('/work-order')}">
                <div class="dropdown-menu-columns">
                  <div class="dropdown-menu-column">
                    <a class="dropdown-item" (click)="closeNav()"
                      [ngClass]="{'active': activeRoute('/work-order/dashboard')}" routerLink="/work-order/dashboard">
                      Dashboard
                    </a>
                    <a class="dropdown-item" (click)="closeNav()"
                      [ngClass]="{'active': activeRoute('/work-order') && !activeRoute('/work-order/dashboard') && !activeRoute('/work-order/service-report') && !activeRoute('/work-order/location-report') && !activeRoute('/work-order/department-report') && !activeRoute('/work-order/insight-report')}"
                      routerLink="/work-order">
                      All Work orders
                    </a>
                    <div class="dropend">
                      <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                        data-bs-auto-close="false" role="button" aria-expanded="false"> Reports </a>
                      <div class="dropdown-menu" [ngClass]="{'show active': isWOReportsActive()}">
                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/work-order/service-report')}"
                          class="dropdown-item" routerLink="/work-order/service-report">
                          Service Report</a>

                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/work-order/location-report')}"
                          class="dropdown-item" routerLink="/work-order/location-report">
                          Location Report</a>
                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/work-order/department-report')}"
                          class="dropdown-item" routerLink="/work-order/department-report">
                          Dapartment Report</a>
                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/work-order/insight-report')}"
                          class="dropdown-item" routerLink="/work-order/insight-report">
                          Insight Report</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <a *ngIf="permissionEvaluator.isUserHaveHKPermission()" class="dropdown-item dropdown-toggle"
                href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                aria-expanded="false"> Housekeeping
              </a>


              <div class="dropdown-menu"
                [ngClass]="{'show active': activeRoute('housekeeping') || activeRoute('lost-and-found') || activeRoute('loan-items') || activeRoute('ppm')}">
                <div class="dropdown-menu-columns">
                  <div class="dropdown-menu-column">
                    <!-- <a *ngIf="permissionEvaluator.isUserHavePPMPermission()" class="dropdown-item"
                      routerLink="/ppm-cycle"
                      [ngClass]="{ active: (activeRoute('ppm') && !activeRoute('ppm-dashboard')) }">
                      <span class="nav-link-title">PPM For Rooms </span>
                    </a>
                    <a *ngIf="permissionEvaluator.isUserHavePPMPermission()" class="dropdown-item"
                      routerLink="/ppm-cycle/ppm-dashboard" [ngClass]="{ active: activeRoute('ppm-dashboard') }">
                      <span class="nav-link-title">PPM Dashboard </span>
                    </a> -->
                    <a class="dropdown-item" [ngClass]="{'active': activeRoute('/work-schedule')}"
                      routerLink="/housekeeping/work-schedule"> Work
                      Schedule </a>
                    <a class="dropdown-item" [ngClass]="{'active': activeRoute('/attendance-week')}"
                      routerLink="/housekeeping/attendance-week">
                      Attendance </a>
                    <a class="dropdown-item"
                      [ngClass]="{'active': activeRoute('/room-status') && !activeRoute('/room-status-reports')}"
                      routerLink="/housekeeping/room-status"> Room Status </a>

                    <a *ngIf="permissionEvaluator.isUserHaveManageLostAndFoundPermission()" class="dropdown-item"
                      routerLink="/lost-and-found" [ngClass]="{'active': activeRoute('lost-and-found')}">
                      Lost & Found
                    </a>
                    <a class="dropdown-item" routerLink="/log-management/loan-items"
                      [ngClass]="{'active': activeRoute('loan-items')}">
                      Loan Register
                    </a>


                    <div class="dropend">
                      <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                        data-bs-auto-close="false" role="button" aria-expanded="false"> Reports </a>
                      <div class="dropdown-menu" [ngClass]="{'show active': isHKReportsActive()}">
                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/room-status-reports')}"
                          class="dropdown-item" routerLink="/housekeeping/room-status-reports">
                          Room Status Report</a>

                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/status-report-daily-status')}"
                          class="dropdown-item" routerLink="/housekeeping/status-report-daily-status">
                          Daily Status Report </a>
                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/guest-ra-rquest')}"
                          class="dropdown-item" routerLink="/housekeeping/guest-ra-rquest">
                          Guest RA Request </a>

                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/status-report-attendance')}"
                          class="dropdown-item" routerLink="/housekeeping/status-report-attendance">
                          Status Report RA </a>
                        <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/status-report-room-status')}"
                          class="dropdown-item" routerLink="/housekeeping/status-report-room-status">
                          Status Report Room </a>
                        <a (click)="closeNav()" [ngClass]="{active: activeRoute('/discrepancy-report')}"
                          class="dropdown-item" routerLink="/housekeeping/discrepancy-report">
                          Discrepancy Report
                        </a>

                      </div>
                    </div>
                    <div class="dropend"> <a class="dropdown-item dropdown-toggle" href="#sidebar-cards"
                        data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
                        Settings
                      </a>
                      <div class="dropdown-menu" [ngClass]="{'show active': isHKSettingsActive()}">
                        <a class="dropdown-item" [ngClass]="{'active': activeRoute('/housekeeping/room-type')}"
                          routerLink="/housekeeping/room-type">
                          Room Type </a>
                        <a class="dropdown-item" routerLink="/housekeeping/locations"> Sectors
                        </a>
                        <a class="dropdown-item" routerLink="/housekeeping/cleaning-preferences"
                          [ngClass]="{'active': activeRoute('/cleaning-preferences')}">
                          Cleaning Preferences </a>
                        <a class="dropdown-item" [ngClass]="{'active': activeRoute('/cleaning-profiles')}"
                          routerLink="/housekeeping/cleaning-profiles"> Cleaning
                          Profile </a>
                        <!-- href="{{ oldSiteURL }}/v2/#/house-keeping/assignment-rules" -->
                        <a [ngClass]="{'active': activeRoute('/assignment-rule')}" class="dropdown-item"
                          routerLink="/housekeeping/assignment-rule">
                          Assignment
                          Rules </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <a *ngIf="permissionEvaluator.isUserHaveLogPermission()" class="dropdown-item dropdown-toggle"
                href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                aria-expanded="false"> Department Logs
              </a>

              <div class="dropdown-menu"
                [ngClass]="{'show': (activeRoute('log-management') && !activeRoute('log-management/incidents') && !activeRoute('log-management/hr-connect')  && !activeRoute('log-management/loan-items') && !activeRoute('log-management/mock-drill') && !activeRoute('log-management/sampling-plan') || activeRoute('roster-manager'))}">

                <a class="dropdown-item" *ngIf="permissionEvaluator?.isUserHaveManageFoPermission()"
                  (click)="closeNav()" routerLink="/log-management/fo/transactions"
                  [ngClass]="{'active': activeRoute('log-management/transactions')}">Front Office
                </a>

                <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveManageFnBPermission()"
                  (click)="closeNav()" routerLink="/log-management/fnb/transactions"
                  [ngClass]="{'active': activeRoute('log-management/fnb/transactions')}">FnB Service
                </a>

                <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveFnbProductionPermission()"
                  (click)="closeNav()" routerLink="/log-management/fnb-production"
                  [ngClass]="{'active': activeRoute('/log-management/fnb-production')}">FnB Production
                </a>

                <a  class="dropdown-item dropdown-toggle"
                  href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                  aria-expanded="false"> Engineering
                </a>
                <div class="dropdown-menu" [ngClass]="{'show' : isEngineeringActive()}">
                  <a *ngIf="permissionEvaluator.isUserHaveManageReadingPermission()" class="dropdown-item"
                  (click)="closeNav()" routerLink="/log-management/dashboard"
                  [ngClass]="{'active': activeRoute('log-management/dashboard')}">Readings</a>

                  <a *ngIf="permissionEvaluator.isUserHaveManageChemicalsPermission()" class="dropdown-item"
                    (click)="closeNav()" routerLink="/log-management/chemicals"
                    [ngClass]="{'active': activeRoute('log-management/chemicals')}">Chemicals</a>

                    <a class="dropdown-item" routerLink="/log-management/hlp-report"
                    [ngClass]="{'active': activeRoute('log-management/hlp-report')}">
                    HLP Report 
                    </a>
                </div>

                <a  class="dropdown-item dropdown-toggle"
                  href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                  aria-expanded="false"> Housekeeping
                </a>
                <div class="dropdown-menu" [ngClass]="{'show' : isHKActive()}">
                  <a class="dropdown-item" routerLink="/log-management/swimming-pool-log"
                  [ngClass]="{'active': activeRoute('/log-management/swimming-pool-log')}">Swimming Pool Log </a>
                  
                  <a class="dropdown-item" routerLink="/log-management/water-bottle-log"
                  [ngClass]="{'active': activeRoute('/log-management/water-bottle-log')}">Water Bottle Log </a>
                </div>
                <a  class="dropdown-item dropdown-toggle"
                  href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                  aria-expanded="false"> HR
                </a>
                <div class="dropdown-menu" [ngClass]="{'show' : isHrActive()}">
                  <a *ngIf="permissionEvaluator.isUserHaveManageContractorsPermission()" class="dropdown-item"
                  routerLink="/log-management/contractors-log"
                  [ngClass]="{'active': activeRoute('log-management/contractors-log')}">Labours/Contractors</a>

                  <a class="dropdown-item" routerLink="/log-management/mod-assigment"
                    [ngClass]="{'active': activeRoute('log-management/mod-assigment')}">MOD Assignment 
                  </a>
                  <a class="dropdown-item" routerLink="/roster-manager" *ngIf="permissionEvaluator?.isUserHaveRosterPermission()"
                    [ngClass]="{'active': activeRoute('roster-manager')}">Roster Manager
                  </a>
                  <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveEmployeeConnectPermission()"
                    [ngClass]="{'active': activeRoute('hr-connect') }" routerLink="/log-management/hr-connect">Employee
                    Connect
                  </a>
                </div>

                <a  class="dropdown-item dropdown-toggle"
                  href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                  aria-expanded="false"> Security
                </a>
                <div class="dropdown-menu" [ngClass]="{'show' : isSecurityActive()}">
                  <a class="dropdown-item" routerLink="/log-management/beach-log"
                  [ngClass]="{'active': activeRoute('/log-management/beach-log')}">Beach Log </a>

                

                  <a class="dropdown-item" routerLink="/log-management/vehicle-log"
                  [ngClass]="{'active': activeRoute('/log-management/vehicle-log')}">Vehicle Log </a>

                  <a class="dropdown-item" routerLink="/log-management/drivers-log"
                  [ngClass]="{'active': activeRoute('/log-management/drivers-log')}">Drivers Log </a>

                  <a class="dropdown-item" routerLink="/log-management/visitors-log"
                  [ngClass]="{'active': activeRoute('/log-management/visitors-log')}">Visitors Log </a>

                  <a class="dropdown-item" *ngIf="permissionEvaluator?.isUserHaveManageWorkLogPermission()"
                  routerLink="/log-management/work-log" [ngClass]="{'active': activeRoute('log-management/work-log')}">
                  Work
                  Log </a>

                  <a *ngIf="permissionEvaluator.isUserHaveManageLuggagePermission()" class="dropdown-item"
                  routerLink="/log-management/luggages"
                  [ngClass]="{'active': activeRoute('log-management/luggages')}">Left
                  Luggages </a>
                </div>




                
                <!-- <a *ngIf="permissionEvaluator?.isUserHaveManageTransactionsPermission()" class="dropdown-item"
                  (click)="closeNav()" routerLink="/log-management/home"
                  [ngClass]="{'active': activeRoute('log-management/home')}">Transactions</a> -->
                <a *ngIf="permissionEvaluator.isUserHaveManageActivityPermission()" class="dropdown-item"
                  (click)="closeNav()" routerLink="/log-management/activities"
                  [ngClass]="{'active': activeRoute('log-management/activities')}">Activities</a>
                
                

                
                


                <a *ngIf="permissionEvaluator.isUserHaveManageLuggagePermission() && permissionEvaluator.isUserHaveManageFoPermission()"
                  class="dropdown-item" routerLink="/budget/p&l-monthly-closure"
                  [ngClass]="{'active': activeRoute('budget/p&l-monthly-closure')}">Monthly P&L
                  Closure </a>

              </div>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveEmployeeConnectPermission()"
                [ngClass]="{'active': activeRoute('hr-connect') }" routerLink="/log-management/hr-connect">Employee
                Connect
              </a>

              <a *ngIf="permissionEvaluator.isUserHaveKraPermission()" class="dropdown-item dropdown-toggle"
                href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                aria-expanded="false"> KRA </a>


              <div class="dropdown-menu" [ngClass]="{'show': activeRoute('goals/kra-home')}">
                <div class="dropdown-menu-columns">
                  <div class="dropdown-menu-column">
                    <a class="dropdown-item" (click)="closeNav()" routerLink="/goals/kra-home"
                      [ngClass]="{'active': activeRoute('all-reviews')}"> Dashboard</a>
                  </div>
                </div>
              </div>

              <div class="dropend">
                <a *ngIf="permissionEvaluator.isUserHaveIRDPermission()" class="dropdown-item dropdown-toggle"
                  href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                  aria-expanded="false">
                  F&B
                </a>

                <div class="dropdown-menu" [ngClass]="{ 'show active': isIRDActive() }">
                  <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/ird-register/list') }"
                    class="dropdown-item" routerLink="/ird-register/list">
                    IRD Menu</a>
                  <a (click)="closeNav()" [ngClass]="{ active: activeRoute('ird-register/errands') }"
                    class="dropdown-item" routerLink="ird-register/errands">
                    IRD Order Mismatch </a>
                </div>
              </div>

            </div>
          </div>
        </li>

        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveQualitypermission()">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active': activeRoute('goals')}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <!-- <i class="ti ti-report-money fs-2"></i> -->
              <i class="ti ti-checklist fs-2"></i>
            </span>
            <span class="nav-link-title"> Quality & Compliance </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{'show': isQualityActive()}">
            <div class="dropdown-menu-column">

              <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isUserHaveInspectionPermission()"
                [ngClass]="{ active: activeRoute('/checklist/templates') }" routerLink="/checklist/templates">
                Checklists
              </a>
              <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isUserHaveInspectionPermission()"
                [ngClass]="{ active: activeRoute('/checklist/inspections') }" routerLink="/checklist/inspections">
                Inspections
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveIncidentPermission()"
                [ngClass]="{'active': activeRoute('/log-management/incidents')}" routerLink="/log-management/incidents">
                Incidents
              </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveAuditPermission()" (click)="closeNav()"
                [ngClass]="{'active': (activeRoute('/audit') && !activeRoute('/audit/schedule'))  }"
                routerLink="/audit/list">Audits
              </a>

              <a class="dropdown-item"
                *ngIf="permissionEvaluator.isUserHaveAuditPermission() && companyUser && currentUserDepartment == 'QUALITY'"
                (click)="closeNav()" [ngClass]="{'active': activeRoute('audit/schedule') }"
                routerLink="audit/schedule">Audits Schedule
              </a>


              <a *ngIf="permissionEvaluator.isUserHaveRegulatoryCompliancesPermission()"
                class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                data-bs-auto-close="false" role="button" aria-expanded="false"> Regulatory Compliances </a>
              <div class="dropdown-menu" [ngClass]="{ 'show active': isRCActive() }">
                <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/regulatory-compliances/licenses') }"
                  class="dropdown-item" routerLink="/regulatory-compliances/licenses">
                  Licenses</a>
                <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/regulatory-compliances/contracts') }"
                  class="dropdown-item" routerLink="/regulatory-compliances/contracts">
                  Contract </a>
                <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/regulatory-compliances/vehicle-fitness') }"
                  class="dropdown-item" routerLink="/regulatory-compliances/vehicle-fitness">
                  Vehicle Fitness </a>
                <a class="dropdown-item" routerLink="/log-management/mock-drill" 
                  [ngClass]="{'active': activeRoute('/log-management/mock-drill')}">Mock Drill </a>
                <a class="dropdown-item" routerLink="/log-management/sampling-plan"
                  [ngClass]="{'active': activeRoute('/log-management/sampling-plan')}">Sampling Plan</a>
                <!-- <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/regulatory-compliances/safety') }"
                  class="dropdown-item" routerLink="/regulatory-compliances/safety">
                  Safety </a> -->
              </div>

              <!-- <div class="dropend">
                <a *ngIf="permissionEvaluator.isUserHaveRegulatoryCompliancesPermission()"
                  class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                  data-bs-auto-close="false" role="button" aria-expanded="false">
                  Regulatory Compliances
                </a>

                <div class="dropdown-menu" [ngClass]="{ 'show active': isRCActive() }">
                  <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/regulatory-compliances/licenses') }"
                    class="dropdown-item" routerLink="/regulatory-compliances/licenses">
                    Licenses</a>
                  <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/regulatory-compliances/contracts') }"
                    class="dropdown-item" routerLink="/regulatory-compliances/contracts">
                    Contract </a>
                </div>
              </div> -->


              <!-- <a class="dropdown-item" (click)="closeNav()"
                *ngIf="permissionEvaluator.isUserHaveRegulatoryCompliancesPermission()"
                [ngClass]="{ active: activeRoute('/regulatory-compliances/legal-documents') }"
                routerLink="/regulatory-compliances/legal-documents">
                Regulatory Compliances
              </a> -->
            </div>
          </div>
        </li>





        <li *ngIf="permissionEvaluator.isUserHaveBudgetPermission()" class="nav-item">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active': activeRoute('goals')}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false"> <span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-report-money fs-2"></i>
            </span> <span class="nav-link-title"> Financials </span> </a>
          <div class="dropdown-menu" [ngClass]="{'show': activeRoute('budget')}">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/dashboard')}"
                  routerLink="/budget/dashboard">
                  Dashboard </a>
                <a class="dropdown-item" (click)="closeNav()"
                  [ngClass]="{'active': activeRoute('/budget') && !activeRoute('/budget/') && !activeRoute('/budget/corporate-expenses') && !activeRoute('/budget/proposals-list')}"
                  routerLink="/budget">Budget</a>
                <a class="dropdown-item" (click)="closeNav()"
                  *ngIf="permissionEvaluator.isCorporate() && permissionEvaluator.isUserHaveBudgetOperationalPermission()"
                  [ngClass]="{'active': activeRoute('/budget/corporate-expenses')}"
                  routerLink="/budget/corporate-expenses">Corporate Expenses</a>
                <div class="dropend" *ngIf="permissionEvaluator.isUserHaveBudgetOperationalPermission()">
                  <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                    data-bs-auto-close="false" role="button" aria-expanded="false"
                    [ngClass]="{'show': activeRoute('budget/pl-summary')}">
                    P&L </a>
                  <div class="dropdown-menu" [ngClass]="{' show': activeRoute('budget/p&l')}">
                    <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/pl-summary')}"
                      class="dropdown-item" routerLink="/budget/pl-summary">
                      P&L Statements</a>
                    <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/pl-dashboard')}"
                      class="dropdown-item" routerLink="/budget/pl-dashboard">
                      P&L Dashboard</a>
                  </div>
                </div>
                <div class="dropend" *ngIf="permissionEvaluator.isCorporate() && permissionEvaluator.isUserHaveBudgetOperationalPermission()">
                  <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                    data-bs-auto-close="false" role="button" aria-expanded="false"
                    [ngClass]="{'show': activeRoute('budget/sales-plan') ||  activeRoute('budget/marketing-plan')}">
                    Sales & Marketing </a>
                  <div class="dropdown-menu" [ngClass]="{' show': activeRoute('budget/sales-plan')}">
                    <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/sales-plan')}"
                      class="dropdown-item" routerLink="/budget/sales-plan">
                     Sales Plan</a>
                     <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/marketing-plan')}"
                      class="dropdown-item" routerLink="/budget/marketing-plan">
                     Marketing Plan</a>
                    <!-- <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/pl-dashboard')}"
                      class="dropdown-item" routerLink="/budget/pl-dashboard">
                      P&L Dashboard</a> -->
                  </div>
                </div>
              </div>

              <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isCorporate()"
                [ngClass]="{'active': activeRoute('/budget/proposals-list') || activeRoute('/budget/capex-budget-approval-advanced')}"
                routerLink="/budget/proposals-list">Approvals </a>

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveBudgetOperationalPermission()"
                [ngClass]="{'active': activeRoute('/budget/rm-planning')}" routerLink="/budget/rm-planning">RM Planning
              </a>
            </div>
          </div>
          <!-- </div> -->
        </li>

        <li *ngIf="permissionEvaluator.isUserHavePropertyInformationPermission()" class="nav-item">
          <a class="nav-link dropdown-toggle" routerLink="/property-information/property-list"  href="#navbar-extra" [ngClass]="{'active show': isPropertyInformationActive()}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-info-circle fs-2"></i>
            </span>
            <span class="nav-link-title"> Property Information </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{'active show': isPropertyInformationActive()}">
            <div class="dropdown-menu-column">
        
              <a class="dropdown-item" [ngClass]="{'active': activeRoute('/property-information/monthly-highlights')}"
                routerLink="/property-information/monthly-highlights" *ngIf="permissionEvaluator.isUserHaveHighlightsPermission()">
                Monthly Highlights
              </a>
              <a class="dropdown-item" [ngClass]="{'active': activeRoute('/property-information/activities')}"
                routerLink="/property-information/activities">
                Activities
              </a>
            </div>
          </div>
        </li>

        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveMainSettingsPermission()">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'show active': isSettingsActive()}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-settings fs-2"></i>
              <!-- <i class="ti ti-checklist fs-2"></i> -->
            </span>
            <span class="nav-link-title"> Settings </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{'show': isSettingsActive()}">
            <div class="dropdown-menu-column">

              <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveTemplatePermission()"
                [ngClass]="{'active': activeRoute('templates') && !activeRoute('/checklist/templates')}"
                routerLink="/templates">
                Template Management
              </a>


              <a *ngIf="permissionEvaluator.isUserHaveSurveyPermission()" class="dropdown-item dropdown-toggle"
                href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                aria-expanded="false"> Survey Management
              </a>


              <div class="dropdown-menu">
                <a class="dropdown-item" href="{{ oldSiteURL }}/v1/#/template/survey/categories"> Survey
                  Category
                </a>
                <a class="dropdown-item" href="{{ oldSiteURL }}/v1/#/template/survey">Survey</a>
              </div>

              <a *ngIf="permissionEvaluator.isUserHaveSettingsPermission() && (permissionEvaluator.isUserHaveManageUserPermission() || permissionEvaluator.isUserHaveManagePropertyPermission() || permissionEvaluator.isUserHaveManageConfigurationPermission())" class="dropdown-item dropdown-toggle"
                href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                aria-expanded="false"> Other Settings
              </a>

              <div class="dropdown-menu"
                [ngClass]="{'show': activeRoute('user-management')|| activeRoute('configuration-settings')|| activeRoute('groups') || activeRoute('departments')|| activeRoute('divisions')}">
                <a *ngIf="permissionEvaluator.isUserHaveManageUserPermission()" class="dropdown-item"
                  [ngClass]="{'active': activeRoute('user-management')&&!activeRoute('groups')&&!activeRoute('divisions') && !activeRoute('departments') && !activeRoute('configuration-settings')}"
                  (click)="closeNav()" routerLink="/user-management"> User Management </a>
                <!-- [ngClass]="{'active': activeRoute('user-management')}" -->

                <a *ngIf="permissionEvaluator.isUserHaveManageUserPermission()" class="dropdown-item"
                  [ngClass]="{'active': activeRoute('user-management/groups')}" routerLink="/user-management/groups">
                  User
                  Group </a>
                <a *ngIf="permissionEvaluator.isUserHaveManagePropertyPermission()" class="dropdown-item" [ngClass]="{'active': activeRoute('property-management')}"
                  routerLink="/property-management"> Property 
                  Management </a>
                <a *ngIf="permissionEvaluator.isUserHaveManagePropertyPermission()" class="dropdown-item" [ngClass]="{'active': activeRoute('divisions')}" routerLink="/divisions">
                  Divisions </a>
                <!-- <a class="dropdown-item" [ngClass]="{'active': activeRoute('departments')}" -->
                <a *ngIf="permissionEvaluator.isUserHaveManagePropertyPermission()" class="dropdown-item" (click)="closeNav()" [ngClass]="{'active': activeRoute('departments')}"
                  routerLink="/departments">
                  Departments
                </a>

                <a *ngIf="permissionEvaluator.isUserHaveManageConfigurationPermission()" class="dropdown-item" [ngClass]="{'active': activeRoute('configuration-settings')}"
                  routerLink="/configuration-settings" (click)="closeNav()">Configuration </a>

              </div>
            </div>
            </div>
            </li>
            
      </ul>
    </div>
    <div class="version-number text-center text-muted small py-2">
      Version 1.0.4 | HotelOps AI
    </div>
  </div>
</aside>